export const useParams = defineStore('params', () => {
  const persistent = useState<PlainParams>(() => ({}))
  const redirect = ref<PlainParams>({})
  const iframe = ref<IFrameParams>()
  const all = ref<PlainParams>({})

  const currency = computed(() => all.value.currency?.toString())

  function initSSR(params: Params) {
    redirect.value = unpack(params)
    persistent.value = unpack(Object.fromEntries(Object.entries(params).filter(([key]) => key !== 'redirect')))
    iframe.value = typeof params.iframe === 'object' ? ({ ...params.iframe } as IFrameParams) : undefined
  }

  function init(params: Params) {
    all.value = Object.assign({}, persistent.value, unpack(params))
  }

  return { persistent, redirect, iframe, all, currency, initSSR, init }
})
