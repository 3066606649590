import _00_45ip_45global from "/myapp/middleware/00.ip.global.ts";
import _02_45ssrparams_45global from "/myapp/middleware/02.ssrparams.global.ts";
import _03_45partner_45global from "/myapp/middleware/03.partner.global.ts";
import _04_45ids_45global from "/myapp/middleware/04.ids.global.ts";
import _05_45params_45global from "/myapp/middleware/05.params.global.ts";
import _06_45conf_45global from "/myapp/middleware/06.conf.global.ts";
import _07_45ab_45global from "/myapp/middleware/07.ab.global.ts";
import _08_45auth_45global from "/myapp/middleware/08.auth.global.ts";
import _09_45user_45global from "/myapp/middleware/09.user.global.ts";
import _10_45tracking_45global from "/myapp/middleware/10.tracking.global.ts";
import _11_45search_45global from "/myapp/middleware/11.search.global.ts";
import manifest_45route_45rule from "/myapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  _00_45ip_45global,
  _02_45ssrparams_45global,
  _03_45partner_45global,
  _04_45ids_45global,
  _05_45params_45global,
  _06_45conf_45global,
  _07_45ab_45global,
  _08_45auth_45global,
  _09_45user_45global,
  _10_45tracking_45global,
  _11_45search_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}