import type { LocationQuery } from 'vue-router'

export default defineNuxtRouteMiddleware((to, _) => {
  if (import.meta.server) {
    const query = to.query
    const params: Params = {
      redirect: {},
    }

    /*
     * A/B-Testing
     */
    if (query.ab) {
      params.ab = (Array.isArray(query.ab) ? query.ab : [query.ab]).toString()
    }

    /*
     *   CNAS-token
     */
    const paramCNAS = query.cnas || query.cnas_token || query['cnas-token']
    if (paramCNAS) {
      params.cnas = paramCNAS.toString()
    }

    /*
     * Update content (HHDWEBCC-4568)
     * query.update is forwarded to conf-api service once on server side.
     * It should not be kept in the URL. It must inserted by CMS editors for every page, they want to update.
     */
    // if (query.update !== undefined) {
    // do not add to params
    // }

    /*
     * Preview mode (HHDWEBCC-4568)
     * Show preview content from CMS, which is not published yet.
     */
    if (query.preview !== undefined) {
      params.preview = true
    }

    /*
     *   Currency
     */
    const paramCurrency = query.currency || query.Currency || query.changeCurrentCurrency
    const currencies = ['AED', 'AUD', 'CAD', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HRK', 'NOK', 'NZD', 'PLN', 'RUB', 'SEK', 'USD', 'ZAR']
    if (paramCurrency && currencies.includes((<string>paramCurrency).toUpperCase())) {
      params.currency = (<string>paramCurrency).toUpperCase()
    }

    /*
     *   JWT
     */
    if (query.jwt) {
      params.jwt = query.jwt.toString()
    } else {
      /*
       * PartnerID & PartnerExtra
       */
      params.partnerid = getPartnerId((query.partnerid || query.PartnerID || query.partnerID || query.partnerId || query.PartnerId)?.toString() ?? '')
      if (params.partnerid) {
        const paramPartnerExtra = query.partnerextra || query.partnerExtra || query.partneridzu || query.PartnerIDzu || query.PartnerIDZU || query.PartnerIDZu
        if (paramPartnerExtra) {
          params.partnerextra = paramPartnerExtra.toString()
        }
      }
    }

    /*
     *   Language
     */
    if (query.language) {
      params.language = query.language.toString()
    }

    /*
     *   SETTINGS
     *
     *   Parameter werden serverseitig ausgelesen und in das Settings-Objekt aufgenommen.
     *   Werden per JS aus der URL entfernt.
     *
     *   - debug: über diesen Parameter kann die Dubug-Toolbar ein- bzw. ausgeschaltet werden.
     *   - tracking: über diesen Parameter kann das Tracking ein/ausgeschaltet werden.
     */
    if (Object.keys(query).includes('debug')) {
      params.debug = true
    }
    if (query.tracking) {
      params.tracking = boolean(query.tracking.toString())
    }
    if (query.editor) {
      params.editor = boolean(query.editor.toString())
    }

    if (query.engine) {
      for (const curr of ['elastic', 'v5']) {
        if (query.engine === curr) {
          params.engine = query.engine
        }
      }
    }

    /*
     *   Redirect params
     */
    params.redirect = {}
    // urchin tracking module (Standard im Web um mitzuteilen, woher jemand kommt (trafficsouce))
    // utm_source           source/referrer
    // utm_medium	          marketing medium
    // utm_campaign	        campaign's name
    // utm_content          ad's content
    // utm_term	            ad's keyword
    // utm_id
    // utm_source_platform
    // utm_campaign_id
    // utm_creative_format
    // utm_marketing_tactic
    // _ga                  GA UA Cross Domain Tracking
    // _gl                  GA4 Cross Domain Tracking
    for (const utmparam of [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'utm_id',
      'utm_source_platform',
      'utm_campaign_id',
      'utm_creative_format',
      'utm_marketing_tactic',
    ]) {
      if (query[utmparam]) {
        params.redirect[utmparam] = query[utmparam]?.toString() || ''
      }
    }

    // Crossdomain
    for (const utmparam of ['_ga', '_gl']) {
      if (query[utmparam]) {
        params.redirect[utmparam] = query[utmparam]?.toString() || ''
      }
    }

    // dclid	Click ID - Google (Campaign Manager and Display & Video 360 reporting integrations)
    // gclid	Click ID - Google (Google Ads and Search Ads 360 integrations)
    // gclsrc	Click ID - Google
    // fbclid	Click ID - Facebook Meta
    // msclkid	Click ID - Bing / Microsoft
    // trv_reference	Trivago "Click ID"
    // bookingTrackId	Idealo "Click ID"
    params.clid = []
    for (const clidparam of ['gclsrc', 'dclid', 'gclid', 'fbclid', 'msclkid', 'trv_reference', 'bookingTrackId']) {
      if (query[clidparam]) {
        params.redirect[clidparam] = query[clidparam]?.toString() || ''
        params.clid.push(`${clidparam}:${query[clidparam]}`)
      }
    }

    /*
     *   Click id
     */
    if (!params.clid.length) {
      if (query.clid) {
        params.clid.push(query.clid.toString())
      } else {
        delete params.clid
      }
    }

    // spMailingID	Acoustic (Silverpop)
    // spUserID	Acoustic (Silverpop)
    // spJobID	Acoustic (Silverpop)
    // spReportId	Acoustic (Silverpop)
    for (const spparam of ['spMailingID', 'spUserID', 'spJobID', 'spReportId']) {
      if (query[spparam]) {
        params.redirect[spparam] = query[spparam]?.toString() || ''
      }
    }

    //   heID	Hashed E-Mail ID
    if (query.heID) {
      params.redirect.heID = query.heID.toString()
    }

    /*
     *   iFrame
     *
     */
    const ifr = iframe(query)
    if (ifr) {
      params.iframe = ifr
    }

    useParams().initSSR(params)
  }
})

const colorParams = [
  'color1',
  'color2',
  'color3',
  'color-thm',
  'color-thm-light',
  'color-thm-hover',
  'color-thm-contrast',
  'color-cta',
  'color-cta-hover',
  'color-cta-contrast',
  'color-hlt',
  'color-hlt-hover',
  'color-hlt-light',
  'color-hlt-contrast',
  'color-err',
]

function iframe(query: LocationQuery): boolean | Params {
  if (!(query.iniframe || query.iframe) || !boolean((query.iniframe || query.iframe)!.toString())) {
    return false
  }

  // Prevent css-injections https://jira.hotelplan.com/browse/IHGWEBCC-408
  Object.entries(query).forEach(([key, value]) => {
    if (colorParams.includes(key) && (!value || !/^\w{3,6}$/.test(value!.toString()))) {
      delete query[key]
    }
  })

  // Legacy
  if (query.iniframe) {
    const iframe: Params = {
      iframe: true,
      layout: query.ShowFooterLinks === undefined || query.ShowFooterLinks === 'true' || query.ShowFooterLinks === '1' ? 'footer' : 'base',
      'color-thm': query.color1?.toString(),
      'color-cta': query.color2?.toString(),
      'color-cta-contrast': query.color3?.toString(),
    }
    Object.keys(iframe).forEach((key) => {
      if (iframe[key as keyof typeof iframe] === undefined) {
        delete iframe[key as keyof typeof iframe]
      }
    })
    return iframe
  }
  const iframe: Params = {
    iframe: true,
    layout: layout(query),

    'color-thm': query['color-thm']?.toString(),
    'color-thm-hover': query['color-thm-hover']?.toString(),
    'color-thm-light': query['color-thm-light']?.toString(),
    'color-thm-contrast': query['color-thm-contrast']?.toString(),

    'color-cta': query['color-cta']?.toString(),
    'color-cta-hover': query['color-cta-hover']?.toString(),
    'color-cta-contrast': query['color-cta-contrast']?.toString(),

    'color-hlt': query['color-hlt']?.toString(),
    'color-hlt-hover': query['color-hlt-hover']?.toString(),
    'color-hlt-light': query['color-hlt-light']?.toString(),
    'color-hlt-contrast': query['color-hlt-contrast']?.toString(),

    'color-err': query['color-err']?.toString(),
  }

  Object.keys(iframe).forEach((key) => {
    if (iframe[key as keyof typeof iframe] === undefined) {
      delete iframe[key as keyof typeof iframe]
    }
  })
  return iframe
}

/*
 * Four different layouts:
 *  - base: only page content
 *  - header: header + page content
 *  - footer: page content + footer
 *  - full: header + page content + footer
 */
function layout(query: LocationQuery): IFrameParamsLayout {
  const layouts = ['base', 'header', 'footer', 'full']
  if (query.layout && layouts.includes(query.layout.toString())) {
    return query.layout.toString() as IFrameParamsLayout
  }
  // TODO: Soll weiterhin der Footer im default-Fall sichtbar sein?
  return 'footer'
}

function boolean(input: string) {
  try {
    return typeof input === 'string' ? JSON.parse(input.toLowerCase()) : false
  } catch (e) {
    return false
  }
}
